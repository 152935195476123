<template>
  <div class="content-layout">
    <div class="select-container">
      <a-button v-if="$hasPermissions(['rcs:cpu_feature:create'])" type="primary" @click="addCpuFunction"
        >新增</a-button
      >
      <div>
        <a-select style="width: 150px" v-model="searchOption">
          <a-select-option
            v-for="option in this.$constRepository.cpuFunction.selectOption"
            :key="option.value"
            :value="option.value"
            >{{ option.label }}</a-select-option
          >
        </a-select>
        <a-input
          v-model="searchKeyWords"
          placeholder="请输入关键字"
          style="width: 200px; margin-left: 10px"
          @keyup.enter="doSearch"
          allow-clear
        />
        <a-button type="primary" style="margin-left: 10px" @click="doSearch">搜索</a-button>
      </div>
    </div>
    <div class="table-container">
      <a-table
        class="list-table"
        :columns="this.$constRepository.cpuFunction.columns"
        :data-source="tableData"
        :pagination="false"
        @change="tableChange"
        rowKey="id"
      >
        <template slot="lastModificationTime" slot-scope="scope">
          {{ scope | parseTime | orNA }}
        </template>
        <template slot="action" slot-scope="scope">
          <div>
            <a-button
              v-if="$hasPermissions(['rcs:cpu_feature:update'])"
              class="green-btn"
              ghost
              @click="editHandler(scope)"
              >编辑</a-button
            >
            <a-divider type="vertical" />
            <a-button
              v-if="$hasPermissions(['rcs:cpu_feature:get'])"
              class="green-btn"
              ghost
              @click="detailHandler(scope)"
              >详情</a-button
            >
            <a-divider type="vertical" />
            <a-popconfirm okText="是" cancelText="否" title="是否删除中控功能?" @confirm="delHandler(scope)">
              <a-button v-if="$hasPermissions(['rcs:cpu_feature:delete'])" class="red-btn" ghost>删除</a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pageInfo.page + 1"
          :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
          show-quick-jumper
          show-size-changer
          :total="pageInfo.totalElements"
          @showSizeChange="onShowSizeChange"
          @change="onChange"
          :pageSizeOptions="['10', '20', '30', '40']"
        />
        <br />
      </div>
    </div>
    <cpu-function-add-or-edit-modal
      v-if="addOrEditVisible"
      :visible.sync="addOrEditVisible"
      :item="currentItem"
      @updateView="getList"
    />
    <cpu-function-detail-modal v-if="detailVisible" :visible.sync="detailVisible" :item="currentItem" />
  </div>
</template>
<script>
import lodash from 'lodash';
import CpuFunctionAddOrEditModal from './components/CpuFunctionAddOrEditModal';
import CpuFunctionDetailModal from './components/CpuFunctionDetailModal';

export default {
  name: 'CpuFunctionManagement',
  components: { CpuFunctionAddOrEditModal, CpuFunctionDetailModal },
  data() {
    return {
      searchKeyWords: '',
      searchOption: this.$constRepository.cpuFunction.optionEnum.FEATURE,
      addOrEditVisible: false,
      detailVisible: false,
      tableData: [],
      pageInfo: {
        page: 0,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
      currentItem: undefined,
      order: '',
      orderField: '',
    };
  },
  methods: {
    /** 新增中控功能 */
    addCpuFunction() {
      this.currentItem = undefined;
      this.addOrEditVisible = true;
    },
    /** 编辑 */
    editHandler(scope) {
      this.currentItem = lodash.cloneDeep(scope);
      this.addOrEditVisible = true;
    },
    /** 详情 */
    detailHandler(scope) {
      this.currentItem = scope;
      this.detailVisible = true;
    },
    /** 删除 */
    async delHandler(scope) {
      await this.$apiManager.cpuFunction.delCpuFunction(scope.feature);
      this.$message.success('删除成功');
      this.getList();
    },
    doSearch() {
      this.pageInfo.page = 0;
      this.getList();
    },
    async listCpuFunction() {
      let payload = {
        feature:
          this.searchOption === this.$constRepository.cpuFunction.optionEnum.FEATURE && this.searchKeyWords !== ''
            ? this.searchKeyWords
            : undefined,
        remark:
          this.searchOption === this.$constRepository.cpuFunction.optionEnum.REMARK && this.searchKeyWords !== ''
            ? this.searchKeyWords
            : undefined,
        page: this.pageInfo.page,
        size: this.pageInfo.size,
      };
      if (this.order && this.orderField) {
        payload = { ...payload, orderBy: `${this.orderField} ${this.order}` };
      }
      const res = await this.$apiManager.cpuFunction.getCpuFunctionList(payload);
      this.tableData = res.data.elements;
      this.$set(this.pageInfo, 'totalElements', res.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', res.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', res.data.currentPage);
    },
    /** 获取中控功能列表 */
    async getList() {
      await this.listCpuFunction();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 0;
        await this.listCpuFunction();
      }
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 0;
      this.$set(this.pageInfo, 'size', pageSize);
      this.getList();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber - 1);
      this.getList();
    },
    tableChange(p, f, s) {
      if (s.order) {
        this.order = s.order === 'descend' ? 'desc' : 'asc';
        this.orderField = s.field;
      } else {
        this.order = '';
        this.orderField = '';
      }
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
.content-layout {
  padding:16px 24px;
  .select-container {
    display: flex;
    justify-content: space-between;
  }
  .table-container {
    .list-table{
      margin-top: 1%;
    }
    .green-btn {
      color: green;
      border: none;
    }

    .red-btn {
      border: none;
      color: red;
    }
    .pagination {
      margin-top: 3%;
    }
  }
}
</style>
